
import Foundation from './foundation'
import FoundationService from './service'


import { loadStripe } from '@stripe/stripe-js';

import {getSettings} from '@/config'
const settings = getSettings()

const ROLE_TRANSL = {
  brand_manager: 'Brand manager',
  brand_corporate: 'Brand',
  brand_director: 'Brand director',
  marketing_manager: 'Marketing manager',
  production_manager: 'Production manager',
  photographer: 'Photographer',
  videographer: 'Videographer',
  model: 'Model',
  stylist: 'Stylist',
  hairstylist: 'Hair stylist',
  makeupartist: 'Makeup artist',
  founder: 'Founder',
  staff: 'Associate',
  assistant: 'Photography assistant',
  artdirector: 'Art director',
  location: 'Location',
};
export const ROLE_LIST = Object.keys(ROLE_TRANSL).map(k => ROLE_TRANSL[k]);

const _f_check = (k, r) => k === 'staff' ? r['founder'] ? false : r[k] : r[k];
export const roleString = roles => Object.keys(roles).filter(k => _f_check(k, roles)).map(r => ROLE_TRANSL[r]).join(', ');
export const roleList = roles => Object.keys(roles).filter(k => _f_check(k, roles)).map(r => ROLE_TRANSL[r]);

class PagedList {
  constructor(page_func){
    this._func = page_func
    this.reset();
  }
  

  async reset() {
    this._list = {};
    this._current_page = 0;
    this._page_count = 1000;
  }

  get value() { return Object.entries(this._list).toSorted( (a,b) => a[0] - b[0]).map(v => v[1]).flat(); }
  get done() { return this._current_page >= this._page_count; }

  async load() {
    if(this._current_page < this._page_count || this._page_count < 0) {
      let i = this._current_page++,
          data = await this._func(i);
      this._list[i] = data.data;
      this._page_count = data.pages;
      return this.value;
    }
    return false;
  }
}

export class AnonUser {

  static authenticated = false;
  static _api = new Foundation(settings.foundation);

  static async _call() { return await this._api._call(...arguments); }

  static async registerUser(invite_id, user_data) {
    return await this._call({path:`/invites/${invite_id}/register`, method:'post', data:user_data})
  }

  static async registerApplication(invite_id, application_data) {
    return await this._call({path:`/invites/${invite_id}/apply`, method:'post', data:application_data})
  }

  static async uploadApplicationimage(invite_id, image) {
    return await this._call({path:`/invites/${invite_id}/image`, data:{original:image}, method:'post', extra_headers:{'Content-Type': 'multipart/form-data'}});
  }
  
  static async getIntent(invite_id) {
    return await this._call({path:`/invites/${invite_id}/intent`, method:'post'})
  }

  static async searchPlaces(params) { return this._api.searchPlaces(params); }
  static async getPlace(id) { return this._api.getPlace(id); }

  static async supportRequest(data) {
    return await this._call({path:`/support/`, method:'post', data})
  }

  static async interested(email) {
    return await this._call({path: `/interested/`, method:'post', data:{email}})
  }

  static async getStripe() {
    return await loadStripe(settings.stripe_key);
  }
}

export class User extends FoundationService {

  cache_key = 'user_cache';

  constructor(api) {
    super(api);
    this._following = new PagedList(p => this._api.get_following(this.id, p));
    this._activity = new PagedList(p => this._api.getActivity(p))
  }
  
  async fetchData() {
    return await this._api.getUser(this.id);
  }

  async cancelSubscription() {
    this._api.cancelSubscription();
  }
  
  get id() { return this._data ? this._data.id : null; }

  get name() { return this._data.first_name; }
  set name(value) { this._data.first_name = value; }

  get about() { return this._data.about; }
  set about(value) { this._data.about = value; }

  get roles() { return this._data.roles; }
  get role() { return this._data.role; }
  
  get settings() { return this._data.settings; }

  get payment_status() { return this._data.payment_status; }

  get authenticated() { return this._api.authenticated; }
  get activity() { return this._activity; }
  get following() { return this._following; }
  get follower() { return this._data.follower; }

  get images() { return this._data.images; }

  get videos() { return this._data.videos; }

  get contact_info() { return this._data.contact_info; }
  set contact_info(value) { this._data.contact_info = value; }

  get planned_locations() { return this._data.planned_locations; }
  set planned_locations(value) { this._data.planned_locations = value; }

  get location() { return this._data.location; }
  set location(value) { this._data.location = value; }

  get sent_invitations() { return this._data.sent_invitations; }
  get follow_requests() { return this._data.follow_requests; }

  get nearby_users() { return this._data.nearby_users; }
  
  get unread_messages() { return this._data.unread_messages; }
  set unread_messages(value) { this._data.unread_messages = value; }

  static async registerUser(invite_id, email) {
    let api = new Foundation(settings.foundation);
    return await api.invite(invite_id, email);
  }

  async addUser(user_data) {
    if(this.roles.staff) {
      return await this._api.addUser(user_data);
    }
  }

  static async loginUser(username, password) {
    let api = new Foundation(settings.foundation);
    let credentials = await api.login(username, password);
    localStorage.setItem(settings.auth_cache, JSON.stringify(credentials));
    let user = new User(api);
    await user.refresh();
    return user;
  }

  static async requestPasswordReset(email) {
    let api = new Foundation(settings.foundation);
    await api.requestPasswordReset(email);
  }

  static async resetPassword(id, password1, password2) {
    let api = new Foundation(settings.foundation);
    await api.resetPassword(id, {password1, password2});
  }

  logout(cb) {
    this._api.logout();
    localStorage.removeItem(settings.auth_cache);
    localStorage.removeItem(this.cache_key);
    if(cb) cb();
  }

  async getAllRoles() {
    return await this._api.getRoles();
  }

  async update(data) {
    data = data || this._data;
    this._data = await this._api.updateUser(data);
    this._set_cache();
  }

  async referUser(email, invite_id='', message='', send=true, promo='') {
    let data = await this._api.referUser(email, invite_id, message, send, promo);
    if(data.success) this._data.sent_invitations = [data.invite, ...this.sent_invitations]
    return data;
  }

  async accept(id) {
    let data = await this._api.accept(id);
    await this.refresh();
    return data;
  }

  async follow(id) {
    let data = await this._api.follow(id);
    await this.refresh();
    return data;
  }

  async unfollow(id) {
    let data = await this._api.unfollow(id);
    await this.refresh();
    return data;
  }

  follows(id) {
    if(this.following.value) {
      let friend = this.following.value.find(u => u.id === id);
      return typeof friend !== 'undefined';
    }
    return true;
  }

  hasRequested(id) {
    let request = this._data.sent_requests.find(request => request.recipient.id === id );
    return typeof request !== 'undefined';
  }

  async addContact(contact) { 
    return await this._api.updateContact(contact, this.id);
  }
  async removeContact(contact_id) { return await this._api.delContact(contact_id, this.id); }

  async getContact(id) { 
    let data = await this._api.getUser(id);
    return this.fromData(data);
  }

  async resetCache() {
    return await this._api.resetActivityCache();
  }

  async getActivity() {
    return await this._api.getActivity()
  }

  async addImage(image) {
    let response = await this._api.addImage(image, this.id);
    this._data.images[this._data.images.length]  = response;
    this.refresh(false);
    return response;
  }

  async addVideo(video) {
    let response = await this._api.addVideo(video, this.id);
    this._data.images[this._data.images.length]  = response;
    this.refresh(false);
    return response;
  }

  async removeImage(imageId) {
    let response = await this._api.removeImage(imageId);
    this._data.images = this._data.images.filter(img => img.id != imageId);
    this.refresh(false);
    return response;
  }

  async removeVideo(videoId) {
    let response = await this._api.removeVideo(videoId);
    this._data.videos = this._data.videos.filter(img => img.id != videoId);
    this.refresh(false);
    return response;
  }

  async searchPlaces(params) { return this._api.searchPlaces(params); }
  async getPlace(id) { return this._api.getPlace(id); }

  async getPlannedLocations(uid) {
    return await this._api.getPlannedLocations(uid);
  }

  async updatePlannedLocation(data) {
    let response = await this._api.updatePlannedLocation(data);
    this._data.planned_locations = response;
    this._set_cache();
    return response;
  }

  async removePlannedLocation(id) {
    let response = await this._api.removePlannedLocation(id);
    this.planned_locations = response;
    this._set_cache();
    return response;
  }

  async setLocation(latitude, longitude) {
    const location = await this._api.setLocation({latitude, longitude});
    this._data.location = location;
    return location;
  }

  async query(q) { return await this._api.queryUsers(q); }


  /* Opportunities */

  async listOpportunities() {
    return await this._api.listOpportunities();
  }

  async getOpportunity(opportunity_id) {
    return await this._api.getOpportunity(opportunity_id);
  }

  async postOpportunity(data) {
    return await this._api.postOpportunity(data);
  }

  async updateOpportunity(opportunity_id, data) {
    return await this._api.updateOpportunity(opportunity_id, data);
  }

  async deleteOpportunity(opportunity_id, data) {
    return await this._api.deleteOpportunity(opportunity_id);
  }

  async getComments(opportunity_id) {
    return await this._api.getComments(opportunity_id);
  }

  async postComment(opportunity_id, comment) {
    return await this._api.postComment(opportunity_id, comment);
  }

  async deleteComment(opportunity_id, comment_id) {
    return await this._api.deleteComment(opportunity_id, comment_id);
  }

  /* Topics */

  async listTopics() {
    return await this._api.listTopics();
  }

  async listThreads(topic_id, thread_start=null) {
    return await this._api.listThreads(topic_id, thread_start);
  }

  async getThread(thread_id) {
    return await this._api.getThread(thread_id);
  }

  async postTopic(title, description) {
    return await this._api.postTopic({title, description})
  }

  async postThread(message, topic_id, reply_to=null, title='') {
    return await this._api.postThread(topic_id, reply_to, {message, title});
  }

  async deleteTopic(topic_id) {
    return await this._api.deleteTopic(topic_id);
  }

  async deleteThread(topic_id, thread_id) {
    return await this._api.deleteThread(topic_id, thread_id);
  }

  async supportRequest(data) {
    return await this._api.supportRequest(data);
  }

  async registerNotifications(token, platform) {
    return await this._api.registerNotifications({token, platform});
  }

  async adminInviteStats() {
    if(this.roles.staff)
      return this._api.adminInviteStats();
    return {};
  }

  async acceptApplication(id) {
    if(this.roles.staff)
      return this._api.resolveApplication(id, true);
    return {}
  }

  async declineApplication(id) {
    if(this.roles.staff)
      return this._api.resolveApplication(id, false);
    return {}
  }

}
