
<template>
  <v-app id="locational">
    <Suspense>
      <RouterView />
    </Suspense>
  </v-app>
</template>

<script setup>
  import { onMounted, watch} from 'vue';
  import { App as CapacitorApp } from '@capacitor/app';
  import { RouterView, useRouter } from 'vue-router';
  import {Geolocation} from '@capacitor/geolocation'
  import { useNotifications } from '@/stores/notifications';
  import {useUserStore} from '@/stores'
  import {useMessagingStore} from '@/stores/messaging'
  const user = useUserStore().user;    
  const messaging = useMessagingStore();

  const router = useRouter();
  const notifications = useNotifications()

  notifications.handleEvents(event => {
    let channel_id = event.notification.data.aps['thread-id'];
    if(channel_id) {
      let components = channel_id.split('/');
      if(components.length > 1) router.push({name:components[0], params:{id:components[1]}});
      else router.push({name:'channel', params:{channel_id}});
    }
  });

  onMounted(() => {
      // Establish connection on app load
      if(user && user.authenticated) {
        messaging.connect(String(user.id), '')
                  .then(() => messaging.update())
                  .then(() => messaging.updateUser(user.name, user.images[0].tiny));
      }
      
      CapacitorApp.addListener('appStateChange', (state) => {
        if (state.isActive && user?.authenticated) {
          messaging.connect(String(user.id), '')
                  .then(() => messaging.updateUser(user.name, user.images[0].tiny));
          if(user.settings.geolocate) {
            Geolocation.getCurrentPosition()
                    .then(response => {
                      let coords = response.coords;
                      user.value.setLocation(coords.latitude, coords.longitude);
                    });
          }
        } 
      });
    });

</script>

<style>
</style>



