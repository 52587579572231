import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import SendbirdChat from '@sendbird/chat';
import {getSettings} from '@/config'
import { GroupChannelModule, GroupChannelListOrder, GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { MessageCollection, MessageFilter } from '@sendbird/chat/groupChannel';

const settings = getSettings();
const sendbird = new SendbirdChat.init({appId:settings.sb_key, modules: [new GroupChannelModule(),]});

const _channel_name = (id1, id2) => [id1, id2].sort().join('-')

export const useMessagingStore = defineStore('messaging', () => {
    
    const user = ref(null);
    const channel = ref(null);
    const channels = ref([]);
    const messages = ref([]);
    const unread = computed(() => channels.value.reduce((a,c) => a + c.unreadMessageCount, 0));

    const is_typing = ref(false)

    sendbird.groupChannel.addGroupChannelHandler('1', new GroupChannelHandler({
        onTypingStatusUpdated: a => is_typing.value = a.isTyping,
        onMessageReceived: (ch, incoming) => {
            if(ch.name != channel.value?.name)
                 channels.value = [ch, ...channels.value.filter(c => c.name != ch.name)]
        }
    }));
    
    async function connect(userId) {
        await sendbird.disconnect();
        user.value = await sendbird.connect(String(userId));
    }
    
    async function update() {
        const groupChannelCollection = sendbird.groupChannel.createGroupChannelCollection({
            order: GroupChannelListOrder.LATEST_LAST_MESSAGE,
        });
        while (groupChannelCollection.hasMore) {
            let c = await groupChannelCollection.loadMore();
            channels.value = [...channels.value, ...c];
        }
    }

    async function updateUser(nickname, profileImageUrl) {
        return await sendbird.updateCurrentUserInfo({nickname, profileImageUrl});
    }
    
    async function createChannel(id1, id2, channel_name) {
        await sendbird.setChannelInvitationPreference(true);
        return await sendbird.groupChannel.createChannel({
            name: channel_name,
            invitedUserIds: [id1, id2],
            operatorUserIds: [id1, id2],
            isDistinct: true,
        });
    }

    async function findChannel(channel_name) {
        const params = {includeEmpty: true, channelNameContainsFilter: channel_name,};
        const query = sendbird.groupChannel.createMyGroupChannelListQuery(params);
        const channels = await query.next()
        if(channels.length > 0) return channels[0];
        return null;
    }

    async function getChannel(user1, user2) {
        const [id1, id2] = [String(user1.id), String(user2.id)];
        try {
            const channel_name = _channel_name(id1, id2);
            var channel = await findChannel(channel_name);
            if(channel) return channel;
            return await createChannel(id1, id2, channel_name);
        } catch (error) {
            console.error('Error creating or opening channel:', error);
            throw error;
        }
    }

    let messageCollection = null;

    async function open(channel_url, onMessage) {
        
        channel.value = await sendbird.groupChannel.getChannel(channel_url);
        if(messageCollection) messageCollection.setMessageCollectionHandler(null);
        
        const messageFilter = new MessageFilter();
        messageCollection = channel.value.createMessageCollection({
            filter: messageFilter,
            startingPoint: Date.now(),
            limit: 100
        });
        
        while(messageCollection.hasNext) {
            const m = await messageCollection.loadNext();
            messages.value = [...messages.value, ...m.reverse()];
        }
                
        messageCollection.setMessageCollectionHandler({
            onMessagesAdded: async (context, channel, incoming) => {
                messages.value = [...messages.value, ...incoming];
                await channel.markAsRead();
                if (onMessage) onMessage(messages);
              },
            });
    }

    async function close() {
        if(messageCollection) messageCollection.setMessageCollectionHandler(null);
        if(channel.value != null) channel.value = null;
    }

    async function send(message) {
        if (channel.value && message.length > 0) {
            const params = { message };
            await channel.value.sendUserMessage(params)
        }
    }

    const is_connected = computed(() => !!channel.value);

    return { channels, channel, messages, unread, is_typing, is_connected, connect, update, updateUser, getChannel, open, close, send };
});
